import React, {Fragment} from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import WcIcon from '@material-ui/icons/Wc';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
function getIconByType(type) {
  if(type === 'body') {
    return <AccessibilityIcon/>
  }
  else if(type === 'gender') {
    return <WcIcon/>
  }
  else if(type === 'stomach') {
    return <FastfoodIcon/>
  }
  else if(type === 'alcohol') {
    return <Fragment><ScheduleIcon/><LocalBarIcon/></Fragment>
  }
} 
export default function ErrorList({ errors }) {
  console.log(errors)
  return (
    <div>
      <Grid container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}>
      <List component="nav" aria-label="main mailbox folders">
      <Typography variant="h4" gutterBottom>
        Aby zobaczyć wynik:
      </Typography>
        {errors.map((error, index) => (
          <Grid item xs={12} key={index}>
          <ListItem>
            <ListItemIcon>
              {getIconByType(error.type)}
            </ListItemIcon>
            <ListItemText primary={error.message} />
          </ListItem>
          </Grid>
        ))}
      </List>
      </Grid>
    </div>
  )
}
