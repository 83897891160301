import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import { useState} from "react";
import UserDetails from "./UserDetails";
import Alcohol from "./Alcohol";
import Results from "./Results";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { Grid} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const steps = ["Informacje", "Alkohol", "Wynik"];
const BreathalyzerForm = () => {
  const classes = useStyles();
  const handleDataDrinkerChange = e => {
    setDataDrinker({ ...dataDrinker, [e.target.name]: e.target.value });
  };
  const [dataDrinker, setDataDrinker] = useState({
    gender: "",
    weight: "",
    height: "",
    stomach: ""
  });
  const [AlcoholHours, setAlcoholHours] = useState([
    {
      from: "",
      typeOfAlcohol: "",
      amount: 1
    }
  ]);
  const [step, setStep] = useState(0);
  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };
  // Go back to prev Step
  const previousStep = () => {
    setStep(step - 1);
  };
  const addNewAlcoholHour = () => {
    setAlcoholHours([
      ...AlcoholHours,
      {
        from: "",
        period: "",
        typeOfAlcohol: "",
        amount: 1
      }
    ]);
  };

  const deleteLatHour = () => {
    setAlcoholHours(AlcoholHours.slice(0, AlcoholHours.length - 1));
  };

  const handleHourChange = (e, index) => {
    AlcoholHours[index][e.target.name] = e.target.value;
  };
  const handleOnceAgain = e => {
    setAlcoholHours([
      {
        from: "",
        typeOfAlcohol: "",
        amount: 1
      }
    ]);
    setDataDrinker({
      gender: "",
      weight: "",
      height: "",
      stomach: ""
    });
    setStep(0);
  };

  const renderFormStep = step => {
    switch (step) {
      case 0:
        return (
          <UserDetails
            handleDataChange={handleDataDrinkerChange}
            data={dataDrinker}
          />
        );
      case 1:
        return (
          <Alcohol
            alcoholItems={AlcoholHours}
            addNew={addNewAlcoholHour}
            deleteLast={deleteLatHour}
            handleChange={handleHourChange}
          />
        );
      case 2:
        return (
          <Results
            dataDrinker={dataDrinker}
            hoursDrinker={AlcoholHours}
            onceAgain={handleOnceAgain}
          />
        );
    }
  };

  return (
    <Fragment>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>{renderFormStep(step)}</div>
      <div>
        <Button
          disabled={step === 0}
          onClick={previousStep}
          variant="contained"
          className={classes.button}
          color="primary"
        >
          Cofnij
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={nextStep}
          className={classes.button}
          disabled={step == 2}
        >
          {step === 1 ? "Wynik" : "Dalej"}
        </Button>
      </div>
      {step === 2 ? (
        <Grid container justify="center">
          <Alert severity="info" style={{ marginTop: 20 }}>
          Obliczenia alkomatu wirtualnego Polskie Centrum Kalibracji oparte są na teoretycznym algorytmie służącym do określenia stężenia alkoholu we krwi. 
          Szybkość wchłaniania oraz wydalania alkoholu z organizmu zależy od wielu indywidualnych czynników, dlatego wyniki należy traktować wyłącznie jako orientacyjne. 
          Nie mogą być one interpretowane jako równoznaczne ze stanem rzeczywistym zawartości alkoholu we krwi. 
          Wszelka odpowiedzialność i konsekwencje decyzji podjętych na podstawie obliczeń wykonanych alkomatem wirtualnym Polskie Centrum Kalibracji spoczywają na osobie, która z niego korzysta.
          </Alert>
        </Grid>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default BreathalyzerForm;
