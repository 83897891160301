import React, { Fragment, useState, useEffect} from 'react';
import axios from 'axios';
import { Grid} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import ContactDialog from '../Dialogs/ContactDialog'
import Snackbar from '@material-ui/core/Snackbar';
import { apiUrl, shop, password } from "../App"
import MuiAlert from '@material-ui/lab/Alert';
import ErrorList from "./ErrorList"
import {
    Chart,
    BarSeries,
    ArgumentAxis,
    ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getPath = (x, width, y, y1) => `M ${x} ${y1}
   L ${width + x} ${y1}
   L ${width + x} ${y}
   L ${x + width / 2} ${y}
   L ${x} ${y}
   Z`;

const labelStyle = {fontWeight: "bold", fontSize: '12px'};

const BarWithLabel = ({
    arg, barWidth, maxBarWidth, val, startVal, color, value, style,
}) => {
    const width = maxBarWidth * barWidth;
    return (
        <>
            <path d={getPath(arg - width / 2, width, val, startVal)} fill={'#000080'} style={style} />
            <Chart.Label
                x={arg}
                y={val-10}
                dominantBaseline="middle"
                textAnchor="middle"
                style={labelStyle}
            >
                {value + " [‰]"}
            </Chart.Label>
        </>
    );
};
// metoda walidująca dane ciała, które wprowadza użytkownik
function validationUserBodyData(bodyData) {
    const errors = [];
    if (bodyData.gender.length === 0 || (bodyData.gender !== 'male' && bodyData.gender !== 'female')) {
        errors.push({message : 'Określ swoją płeć', type: 'gender'})
    }

    if (bodyData.stomach.length === 0 || (bodyData.stomach !== 'empty' && bodyData.stomach !== 'middle' && bodyData.stomach !== 'full')) {
        errors.push({message : 'Określ poziom zapełnienia Twojego żołądka', type: 'stomach'})
    }
    if(bodyData.height.length === 0 || Number.isNaN(Number(bodyData.height))) {
        errors.push({message : "Określ poprawnie wzrost w cm", type: 'body'})
    }
    if(bodyData.weight.length === 0 || Number.isNaN(Number(bodyData.weight))) {
        errors.push({message : "Określ poprawnie wagę w kg", type: 'body'})
    }
    return errors
}

// metoda do walidacji godzin picia 
function validationAlcoholHours(hours) {
   const status = hours.every((hour) => {
        return typeof hour.from === 'number' && hour.typeOfAlcohol.length > 0
    })
    return status ? [] : [{message: 'Określ poprawnie godziny spożywania alkoholu oraz rodzaj spożywanego alkoholu', type: 'alcohol'}];
}


const Results = ({ dataDrinker, onceAgain, hoursDrinker }) => {

    useEffect(() => {
         if(errors.length ===0) {
         calcPortionsOfAlcohol()
     }
      },[]);

    const [amountPerHours, setAmountPerHours] = useState([])
    const maleFactor = 0.7; // ciało mężczyzny zaś 70 % płynów ustrojowych w stosunku do całkowitej masy ciała
    const femaleFactor = 0.6; //ciało kobiety zawiera 60 % płynów ustrojowych
    let [totalResult, setTotalResult] = useState(null) // wynik wyświetlony po kliknięciu Oblicz
    
    const errors = [].concat(validationUserBodyData(dataDrinker), validationAlcoholHours(hoursDrinker));
    // uruchamianie obliczania funkcji, która liczby poziom spożytego alkoholu na wykresie
    // if(errors.length ===0) {
    //     calcPortionsOfAlcohol()
    // }
    const [openSendMessage, setOpenSendMessage] = useState(false);
    // okno modalne
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open)
    }
    const handleCloseSendMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSendMessage(false);
    };

    const [dialogData, setDialogData] = useState(
        {
            email: '',
            phone: '',
        }
    )
    const handleDialogData = (e) => {
        setDialogData({ ...dialogData, [e.target.name]: e.target.value })
    }

    
    // console.log(dataDrinker);
    // funkcja obliczająca masę płynów ustrojowych w których może rozpuścić się alkohol
    const calcBodyFluidMass = () => {
        let result;
        if (dataDrinker.gender === 'male') {
            result = maleFactor * (+dataDrinker.weight) + calcBMICorrection()
        }
        else if (dataDrinker.gender === 'female') {
            result = femaleFactor * (+dataDrinker.weight) + calcBMICorrection()
        }
        // console.log("Twoja masa ciała w której rozpusci sie alkohol to: ", result)
        return result;

    }
    const calcAmountOfAlcoholInGrams = (amount, alcoholPercantage) => {
        const result = (amount * ((alcoholPercantage) / 100)) * 0.79
        return result;
    }

    const calcBMICorrection = () => {
        const upperLimit = 25;
        const lowerLimit = 18.5;
        const BMI = (+dataDrinker.weight) / Math.pow(((+dataDrinker.height) / 100), 2)
        if (BMI > upperLimit) {
            const result = ((BMI - upperLimit) * Math.pow(((+dataDrinker.height) / 100), 2)) * 0.15
            return result;
        }
        else if (BMI < lowerLimit) {
            const result = ((BMI - lowerLimit) * Math.pow(((+dataDrinker.height) / 100), 2)) * 0.15
            return result;
        }
        else {
            return 0;
        }

    }

    const calcAmountAlcoholPerHours = (amountOfAlcoholInGrams, startDrinkingHour, eliminationInGramsPerHour, bodyFluidMass, stomachFactor) => {
        const amountAlcoholPerHours = [];
        let actualAmountInGrams = amountOfAlcoholInGrams
        while (actualAmountInGrams >= 0) {

            let score = (actualAmountInGrams / bodyFluidMass) * stomachFactor;
            amountAlcoholPerHours.push({
                score: Math.round((score) * 100) / 100,
                hour: ++startDrinkingHour < 10 ? `0${startDrinkingHour}:00` : `${startDrinkingHour}:00`
            });
            actualAmountInGrams -= eliminationInGramsPerHour
        }
        amountAlcoholPerHours.push({
            score: 0,
            hour: ++startDrinkingHour < 10 ? `0${startDrinkingHour}:00` : `${startDrinkingHour}:00`
        });
        // setAmountPerHours(amountAlcoholPerHours)
        return amountAlcoholPerHours
    }
    /**
     * Aktualizacja stanu dawek po godzinie
     * @param {*} amountAlcoholPerHours 
     */



    const calcPortionsOfAlcohol = () => {
        // liczenie porcji po wypiciu piwa:
        const stomachFactor = calcStomachFactor()
        // obliczanie ilości gram eleminowanych na godzinę w zależności od płci
        const eliminationInGramsPerHour = getEliminationInGramsPerHour();
        const summaryDrinkingHours = Object.values(hoursDrinker.reduce((summaryAlcoholHours, drinkingHour, index) => {
            const typeOfAlcohol = drinkingHour.typeOfAlcohol.split('-');
            // obliczenie ilośći wypitych mililitrów
            const amountIMililiters = parseInt(typeOfAlcohol[1]) * drinkingHour.amount
            // zawartość procentowa alkoholu w wypitym trunku
            const percentageValue = parseInt(typeOfAlcohol[2])
            // zawartosc alkoholu policzona w gramach
            const amountOfAlcoholInGrams = calcAmountOfAlcoholInGrams(amountIMililiters, percentageValue)
            // pobranie czasu startu picia:
            const startDrinkingHour = drinkingHour.from
            // pobranie masy ciała w której się rozpuszcza alkohol
            const bodyFluidMass = calcBodyFluidMass()

            // zawartosc alkoholu policzona w gramach dla piwka 6 % i 500 ml
            // obliczenie dawek dla kolejnych godzin
            const amountAlcoholPerHours = calcAmountAlcoholPerHours(amountOfAlcoholInGrams, startDrinkingHour, eliminationInGramsPerHour, bodyFluidMass, stomachFactor)
            return [...summaryAlcoholHours, ...amountAlcoholPerHours]
        }, []).reduce((acc, v) => {
            if (!acc[v.hour]) {
                acc[v.hour] = { hour: v.hour, score: v.score }
            }
            else {
                acc[v.hour].score += v.score
                // zaokrąglenie do 2 miejsc po przecinku
                acc[v.hour].score = Math.round((acc[v.hour].score) * 100) / 100
            }
            return acc;
        }, {}))
        setAmountPerHours(summaryDrinkingHours)
    }

    const getEliminationInGramsPerHour = () => {
        if (dataDrinker.gender === 'female') {
            return 9
        }

        else if (dataDrinker.gender === 'male') {
            return 11
        }
    }

    const calcStomachFactor = () => {
        switch (dataDrinker.stomach) {
            case 'empty':
                return 1
            case 'middle':
                return (5 / 6)
            case 'full':
                return (2 / 3)
        }
    }

    const sendClientContactData = (data) => {
        console.log("dane do wysylki", data)
        axios.post(`${apiUrl}/api/save-client-contact`, {
            phone: data.phone,
            email: data.email,
            shop: shop,
            password: password
        })
            .then(function (response) {
                setOpen(false)
                setOpenSendMessage(true);
            })
    }
    return (
        <Fragment>
            <Snackbar open={openSendMessage} onClose={handleCloseSendMessage} autoHideDuration={3000} >
                <Alert severity="success">
                    Dziękujemy za dane kontaktowe! Skontaktujemy się z Tobą w sprawie naszej oferty.
                        </Alert>
            </Snackbar>
            { errors.length > 0 ? <ErrorList errors = {errors}/>
            :
            <Chart
                data={amountPerHours}
            >
                <ArgumentAxis />
                <ValueAxis max={7} />
                <BarSeries
                    valueField="score"
                    argumentField="hour"
                    color="blue"
                    pointComponent={BarWithLabel}
                />
            </Chart>}
            <Grid container justify="center">
                <Grid item >
                    <FormControl style={{ paddingBottom: 20, paddingRight: 10 }}>
                        <Button variant="contained" color="primary" onClick={(e) => onceAgain(e)}>
                            Jeszcze raz
                    </Button>
                    </FormControl>
                    <FormControl style={{ paddingBottom: 20 }}>
                        <Button variant="contained" color="primary" onClick={(e) => handleToggle()}
                            endIcon={<SendIcon></SendIcon>}
                        >
                            Kontakt w sprawie oferty
                    </Button>
                        <ContactDialog
                            open={open}
                            handleToggle={handleToggle}
                            submitData={sendClientContactData}
                            data={dialogData}
                            handleChange={handleDialogData}
                        ></ContactDialog>
                    </FormControl>

                </Grid>
            </Grid>

        </Fragment>
    );
}

export default Results