import React, { Fragment } from 'react';
import '../App.css';
import Body from "./Layouts/Body";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000080',
    },
    secondary: green,
  },
  status: {
    danger: 'orange',
  },
});

const App = () => {
  return (
    <Fragment>
      <ScopedCssBaseline>
        <ThemeProvider theme={theme}>
          <Body />
        </ThemeProvider>
      </ScopedCssBaseline>
    </Fragment>
  );
}

export default App;
export const apiUrl = "https://promil.usermd.net"
export const password = "8!#QndQAU8qThO0XMkAKX0Y&s#yFwu*I^n7ID4DvXC1meRgZJ$"
//export const shop = 'promilLab'
//export const shop = 'rowerowe'
export const shop = 'plcentrumkal'
