import React from 'react'
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useState } from "react"
import AlcoholTypes from "../../data/AlcoholTypes"
import LocalBarIcon from '@material-ui/icons/LocalBar';
import ScheduleIcon from '@material-ui/icons/Schedule';
const hours = Array.from(Array(24).keys())

const AlcoholItem = ({ item, handleChange, id }) => {
    const [alcoholHour, setAlcoholHour] = useState(
        {
            from: item.from,
            period: item.period,
            typeOfAlcohol: item.typeOfAlcohol,
            amount: item.amount
        }
    );
    const handleAlcoholHourProperty = (e, index) => {
        setAlcoholHour({ ...alcoholHour, [e.target.name]: e.target.value })
        handleChange(e, index);
    }

    const handleAmountAlcohol = (type, index) => {

        if (type === 'add') {
            setAlcoholHour({ ...alcoholHour, ['amount']: ++alcoholHour.amount })
        }
        else if (type === 'substract') {
            if ((alcoholHour.amount - 1) <= 0) {
                return false;
            }
            setAlcoholHour({ ...alcoholHour, ['amount']: --alcoholHour.amount })
        }
        else {
            return false
        }
        let target = {
            name: 'amount',
            value: alcoholHour.amount
        }
        handleChange({
            target: target
        }, index);

    }

    return (
        <ListItem>
            
            <Grid container spacing={3}>

                <Grid item xs={12} sm={4}>
                    
                    <FormLabel component="legend"><div style={{display: 'flex', alignItems: 'center'}}><ScheduleIcon/> Start</div></FormLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        name="from"
                        onChange={(e) => { handleAlcoholHourProperty(e, id) }}
                        value={alcoholHour.from}
                    >
                        {hours.map((hour, index) => <MenuItem key={hour} value={hour}>{hour < 10 ? `0${hour}` : `${hour}`}:00</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend"><div style={{display: 'flex', alignItems: 'center'}}><LocalBarIcon/> Rodzaj alkoholu</div></FormLabel>
                    <Select
                        labelId="demo-simple-select-label3"
                        id="demo-simple-select3"
                        name="typeOfAlcohol"
                        onChange={(e) => { handleAlcoholHourProperty(e, id) }}
                        value={alcoholHour.typeOfAlcohol}
                        fullWidth
                    >
                        {AlcoholTypes.map((type, index) => <MenuItem key={index} value={type.value}>{type.name}</MenuItem>)}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend">Ilość</FormLabel>
                    <Fab m={4} name="incrementAmount" size="small" color="primary" display="inline" aria-label="add" onClick={(e) => { handleAmountAlcohol('substract', id) }}>
                        <RemoveIcon />
                    </Fab>
                    <Typography name="descrementAmount" variant="h4" gutterBottom display="inline" p={2}>{alcoholHour.amount}</Typography>
                    <Fab m={4} size="small" color="primary" display="inline" aria-label="add" onClick={(e) => { handleAmountAlcohol('add', id) }}>
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default AlcoholItem

