/**
 * Każde pole value musi być opisane:
 * type-volume-alcoholContent
 * 
 * type - rodzaj pitego alkoholu: wino, piwo, wódka
 * volume - objętość wypitego alkoholu
 * alcoholContent - zawartość procentowa wypitego alkoholu
 * type = {p - piwo, w - wino, v - vodka, s - spirytus}
 */
const AlcoholTypes = [
    {
        value: 'p-300-5',
        name: 'Piwo jasne 0,3 l (5%)'
    },
    {
        value: 'p-500-5',
        name: 'Piwo jasne 0,5 l (5%)'
    },
    {
        value: 'p-300-8',
        name: 'Piwo mocne 0,3 l (8%)'
    },
    {
        value: 'p-500-8',
        name: 'Piwo mocne 0,5 l (8%)'
    },
    {
        value: 'w-750-10',
        name: 'Wino marki wino 0,75 l (10%)'
    },
    {
        value: 'w-100-12',
        name: 'Kielszek 100g wina (12%)'
    },
    {
        value: 'v-50-40',
        name: 'Kieliszek 50g wódki (40%)'
    },
    {
        value: 'v-50-50',
        name: 'Kieliszek 50g alkoholu (60%)'
    },
    {
        value: 's-50-96',
        name: 'Kieliszek 50g spirytusu (96%)'
    }
   
]
export default AlcoholTypes