import React, { Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ValidatedForm from "./ValidatedForm"
const ContactDialog = ({ open, handleToggle, submitData, data, handleChange }) => {

    return (
        <Fragment>
            <Dialog open={open} onClose={handleToggle} >
                <DialogTitle>Wyślij nam swoje dane</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Zostaw nam swój adres email albo numer telefonu, a skontaktujemy się z Tobą w sprawie naszej oferty
                    </DialogContentText>
                   <ValidatedForm submit = {submitData}/>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}
export default ContactDialog

