import React from 'react'
import { Formik } from "formik"
import * as EmailValidator from "email-validator"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
const ValidatedForm = ({ submit }) => {
    let isSubmited = false;
    return (
        <Formik
            initialValues={{ email: "", phone: "" }}
            onSubmit={(values, { setSubmitting }) => {
                isSubmited = true;
                console.log("Submitting")
                console.log(values)
                setSubmitting(false)
                submit(values);
            }}
            validate={values => {
                let errors = {};
                if (!values.email && !values.phone) {
                    errors.noData = "Uzupełnij przynajmniej jedno poniższych z pól poprawnie"
                    return errors
                }
                if (values.email) {
                    if (!EmailValidator.validate(values.email)) {
                        errors.email = "Wprowadź poprawny adres email"
                    }
                }
                if (values.phone) {
                    const phoneRegex = /^\+48[0-9]{9}$/
                    if (!phoneRegex.test(values.phone)) {
                        errors.phone = "Podaj numer w formacie +48xxxxxxxxx"
                    }
                }
                return errors
            }}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleSubmit,
                } = props;
                return (

                    <form style={{ marginTop: 30 }} onSubmit={handleSubmit}>
                        {isSubmited && errors.noData && (
                            <div  style={{ color: 'red' }}>{errors.noData}</div>
                        )}
                        <FormLabel>email</FormLabel>
                        <TextField
                            margin="dense"
                            name="email"
                            id="email_input"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                            value={values.email}
                            error={errors.email && touched.email}
                        />
                        {isSubmited && errors.email && touched.email && (
                            <div style={{ color: 'red' }}>{errors.email}</div>
                        )}
                        <FormLabel>telefon</FormLabel>
                        <TextField
                            margin="dense"
                            id="phone_input"
                            name="phone"
                            type="text"
                            fullWidth
                            value={values.phone}
                            onChange={handleChange}
                            error={errors.phone && touched.phone}
                        />
                        {isSubmited && errors.phone && touched.phone && (
                            <div className="input-feedback" style={{ color: 'red' }}>{errors.phone}</div>
                        )}
                        <Button type="submit" disabled={isSubmitting} color="primary" variant="contained"
                            style={{ marginTop: 20 }} onClick={() => { isSubmited = true }}>Wyślij
                        </Button>
                    </form>
                );
            }}
        </Formik>
    )
}
export default ValidatedForm
