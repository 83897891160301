import React, { Fragment } from 'react'
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import AlcoholItem from "./AlcoholItem";

const Alcohol = ({ alcoholItems, addNew, handleChange, deleteLast }) => {

    return (
        <Fragment>
            <List>
                {
                    alcoholItems.map((item, index) => <AlcoholItem key={index} id={index} handleChange = {handleChange} item = {item}/>)
                }

            </List>
            <Button style={{ marginBottom: '15px' }} variant="contained" color="primary" onClick={() => addNew()}>
                Dodaj nowy
            </Button>
            {(alcoholItems.length > 1) ?
            <Button style={{ marginBottom: '15px', marginLeft: '15px' }} variant="contained" color="primary" onClick={() => deleteLast()}>
                Usuń
            </Button> : "" 
            }
        </Fragment >
    )
}

Alcohol.propTypes = {

}

export default Alcohol
