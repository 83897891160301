import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import WcIcon from '@material-ui/icons/Wc';

const UserDetails = ({ handleDataChange, data }) => {
    return (
        <Fragment>
            <Grid container
                spacing={5}
            >

                <Grid item xs={12} sm={6} >
                    <FormLabel><AccessibilityIcon /></FormLabel>
                    <FormControl>
                        <TextField
                            id="height"
                            name="height"
                            helperText="wzrost w cm"
                            value={data.height}
                            onChange={handleDataChange}
                            margin="normal"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormLabel><AccessibilityIcon /></FormLabel>
                    <TextField
                        id="weight"
                        helperText="waga w kg"
                        value={data.weight}
                        onChange={handleDataChange}
                        name="weight"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset" >
                        <FormLabel component="legend"><WcIcon /> Płeć</FormLabel>
                        <RadioGroup onChange={handleDataChange} value={data.gender} aria-label="gender" name="gender" >
                            <FormControlLabel value="male" control={<Radio color="primary" />}
                                label="Mężczyzna" />
                            <FormControlLabel value="female" control={<Radio color="primary" />} label="Kobieta" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset" >
                        <FormLabel component="legend"><FastfoodIcon /> Alkohol był spożywany na</FormLabel>
                        <RadioGroup name="stomach" onChange={handleDataChange} value={data.stomach}
                        >
                            <FormControlLabel value="empty" control={<Radio color="primary" />}
                                label="pusty żołądek" />
                            <FormControlLabel value="middle" control={<Radio color="primary" />}
                                label="w połowie pełny żołądek" />
                            <FormControlLabel value="full" control={<Radio color="primary" />}
                                label="pełny żołądek" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    )
}

UserDetails.propTypes = {

}

export default UserDetails